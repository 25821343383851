import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Box } from 'rebass/styled-components';
import Markdown from 'react-markdown';
import Container from '../components/shared/Container';
import SEO from '../components/shared/SEO';
import juno from '../images/ourwork/juno.svg';
import newsuk from '../images/ourwork/newsuk.svg';
import insurami from '../images/ourwork/insurami.png';

const OurWork = injectIntl(({ intl }) => (
  <div>
    <SEO title={`Tugboat Coding - ${intl.formatMessage({ id: 'ourwork.heading' })}`} />
    <Box my="32px">
      <h1><FormattedMessage id="ourwork.heading" /></h1>
    </Box>
    <Box mb="48px">
      <Markdown source={intl.formatMessage({ id: 'ourwork.body' })} />
    </Box>
    <Box mb="8px">
      <img alt="Juno Bio" src={juno} height="36px" />
    </Box>
    <Box mb="24px">
      <h2>{<FormattedMessage id="ourwork.juno.heading" />}</h2>
    </Box>
    <Box mb="48px">
      <Markdown source={intl.formatMessage({ id: 'ourwork.juno.description' })} />
    </Box>
    <Box mb="16px">
      <img alt="News UK" src={newsuk} height="30px" />
    </Box>
    <Box mb="24px">
      <h2>{<FormattedMessage id="ourwork.newsuk.heading" />}</h2>
    </Box>
    <Box mb="48px">
      <Markdown source={intl.formatMessage({ id: 'ourwork.newsuk.description' })} />
    </Box>
    <Box mb="16px">
      <img alt="Insurami" src={insurami} height="36px" />
    </Box>
    <Box mb="24px">
      <h2>{<FormattedMessage id="ourwork.insurami.heading" />}</h2>
    </Box>
    <Markdown source={intl.formatMessage({ id: 'ourwork.insurami.description' })} />
  </div>
));

const OurWorkPage = () => (
  <Container>
    <OurWork />
  </Container>
);

export default OurWorkPage;
